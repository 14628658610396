@import 'src/client/view/style/constants.scss';

.playersList {
  font-size: 4vh;
}

.noPlayers {
  opacity: 0.5;
  text-align: center;
}
