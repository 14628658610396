@import 'src/client/view/style/constants.scss';

.button {
  border: none;
  text-transform: uppercase;
  background-color: $c-primary;
  color: $c-background;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 24px;
  padding: 9px 18px;
  border-radius: 6px;
  transition: background-color 0.1s ease-in-out, opacity 0.1s ease-in-out;

  &:hover {
    background-color: $c-primary-dark;
  }

  @media ($wide) {
    font-size: 4vh;
    padding: 1.5vh 3vh;
    border-radius: 1vh;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
